import 'mathlive';

import React, {useState, useEffect} from "react";

export default function AnswerBox(props) {
  var {type, sendMessage} = props
  var tableSize = 3;
  if (type && type.startsWith('Table-')) {
    tableSize = parseInt(type.split('Table-')[1])
    type = 'Table'
  } else if (type && type.startsWith('UnorderedList-')) {
    tableSize = parseInt(type.split('UnorderedList-')[1])
    type = 'UnorderedList'
  }

  const isExpression = type === 'Expression'
  const [newAnswer, setNewAnswer] = useState("")
  const [mathSetInput, setMathSetInput] = useState([])
  const [tableInput, setTableInput] = useState([[], []])


  function modifyTableInput(row, col, val) {
    if (row === 0) {
      setTableInput(prev => [
        [...prev[0].slice(0, col), val, ...prev[0].slice(col + 1)],
        prev[1]
      ])
    } else if (row === 1) {
      setTableInput(prev => [
        prev[0],
        [...prev[1].slice(0, col), val, ...prev[1].slice(col + 1)],
      ])
    }
  }

  function modifyMathSetInput(value, index) {
    if (index === 0) {
      setMathSetInput(prev => [
        value, prev[1]
      ])
    } else if (index === 1) {
      setMathSetInput(prev => [
        prev[0], value
      ])
    }
  }

  useEffect(() => {
    if (document.getElementById('formula-input') != null) {
      document.getElementById('formula-input').mathVirtualKeyboardPolicy = 'manual';
    }
  }, [type]);

  useEffect(() => {
    if (tableSize) {
      setTableInput([
        [...Array(tableSize).keys()].map(e => ""),
        [...Array(tableSize).keys()].map(e => "")
      ])
    }
  }, [tableSize])

  function answerOnKeyDown(e) {
    if (e.key.toLowerCase() === "enter") {
      sendMessage(e.target.value, isExpression);
      setNewAnswer("");
    }
  }

  function submitAnswer() {
    if (type === 'Table') {
      const xs = tableInput[0].map(e => parseFloat(e))
      const ys = tableInput[1].map(e => parseFloat(e))
      sendMessage(`[[${String(xs).replaceAll(',', ', ')}], [${String(ys).replaceAll(',', ', ')}]]`, false)

      setTableInput([
        [...Array(tableSize).keys()].map(e => ""),
        [...Array(tableSize).keys()].map(e => "")
      ])
    } else if (type === 'UnorderedList') {
      const xs = '"' + tableInput[0].join('", "') + '"'
      console.log(xs)
      sendMessage(`[${xs}]`, false)

      setTableInput([
        [...Array(tableSize).keys()].map(e => ""),
        [...Array(tableSize).keys()].map(e => "")
      ])
    } else if (type === 'Set') {
      sendMessage('[' + String(mathSetInput) + ']')
      modifyMathSetInput("", 0)
      modifyMathSetInput("", 1)
    } else {
      sendMessage(newAnswer, isExpression)
    }
    setNewAnswer("")
  }

  return <div className="flexRow chatInputRow">
    <label style={{fontSize: "1.2em"}}>Submit Answer: &nbsp;</label>
    {type === 'Expression' ? <math-field 
      onInput={evt => setNewAnswer(evt.target.value)} onKeyDown={answerOnKeyDown}
      id='formula-input' style={{marginRight: "0.5em"}}>
      {newAnswer}
    </math-field> : type === 'Boolean' ? <select className='newAnswer'
        value={newAnswer} onChange={(e) => setNewAnswer(e.target.value)} onKeyDown={answerOnKeyDown}>
        <option value="" disabled>Select True or False</option>
        <option value="True">True</option>
        <option value="False">False</option>
    </select> : type === 'Table' ? <table className='newAnswer'><tbody>
      <tr>
        <td>X values</td>
        {Array(tableSize).fill().map((_, i) => <td key={i} className='pointTableCell'>
          <input type="text" value={tableInput[0][i]} onChange={(e) => modifyTableInput(0, i, e.target.value)}/>
        </td>)}
      </tr>
      <tr>
        <td>Y values</td>
        {Array(tableSize).fill().map((_, i) => <td key={i} className='pointTableCell'>
          <input type="text" value={tableInput[1][i]} onChange={(e) => modifyTableInput(1, i, e.target.value)}/>
        </td>)}
      </tr>
    </tbody></table> : type === 'Set' ? <div>
      (<input type="text" value={mathSetInput[0]} onChange={(e) => modifyMathSetInput(e.target.value, 0)}/>, <input type="text" value={mathSetInput[1]} onChange={(e) => modifyMathSetInput(e.target.value, 1)}/>)&nbsp;&nbsp;
    </div> : type === 'Number' ? <input type="number" className='newAnswer' style={{width: "40%"}} value={newAnswer} 
      onChange={(e) => setNewAnswer(e.target.value)} onKeyDown={answerOnKeyDown}
    /> : type === 'Chat' ? <div style={{display: 'contents'}}><textarea type="text" className='newMessage' style={{width: "200%"}} value={newAnswer} 
    onChange={(e) => setNewAnswer(e.target.value)} onKeyDown={answerOnKeyDown}></textarea>
    <style>{`#chatBox { display: none; }`}</style></div> : type === 'UnorderedList' ? <table className='newAnswer'><tbody>
      <tr>
        <td>Lengths</td>
        {Array(tableSize).fill().map((_, i) => <td key={i} className='pointTableCell' width='100px'>
          <math-field style={{width: '90%'}} onInput={(e) => modifyTableInput(0, i, e.target.value)}>{tableInput[0][i]}</math-field>
        </td>)}
      </tr>
    </tbody></table> : <input type="text" className='newAnswer' style={{width: "40%"}} value={newAnswer} 
      onChange={(e) => setNewAnswer(e.target.value)} onKeyDown={answerOnKeyDown}
    />}
    <button onClick={submitAnswer} disabled={props.disabled}>Submit</button>
  </div>
}