import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"

const BACKEND_URL = 'https://edengage-backend.azurewebsites.net';


export default function Home(props) {
  const [backendMessage, setBackendMessage] = useState(null);
  useEffect(() => {
    document.title = "EdEngage"
    
    fetch(BACKEND_URL)
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch');
      }
      return response.json();
    })
    .then(data => {
      setBackendMessage(data.message);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setBackendMessage('Failed to fetch data from the backend');
    });
  } ,[])

  return (
    <div>
      <h1>EdEngage</h1>
      <p><Link to="/student">For Students</Link></p>
      <p><Link to="/teacher">For Teachers</Link></p>
      {/* prints whether or not Azure backend is running */}
      <p>Backend message: {backendMessage}</p>
    </div>
  )
}