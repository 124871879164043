import React from "react"

function RightTriangle() {
    return (
        <svg width="300" height="300" xmlns="http://www.w3.org/2000/svg">
            <path d="M 50 250 L 50 50 L 250 250 Z" fill="none" stroke="black" stroke-width="2" />
            <text x="30" y="160" font-family="Verdana" font-size="20" fill="black">a</text>
            <text x="140" y="275" font-family="Verdana" font-size="20" fill="black">b</text>
            <text x="160" y="150" font-family="Verdana" font-size="20" fill="black">c</text>
        </svg>
    )
}

export default RightTriangle