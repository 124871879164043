import React, {useState, useEffect} from "react"
import { Link, useNavigate } from "react-router-dom"
import { decodeToken } from "../utils/auth";
import LoginRow from "../components/LoginRow";

export default function StudentLogin(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    if (props.userID) {
      navigate('/student')
    }
  }, [navigate, props.userID])

  async function attemptLogin(e) {
    e.preventDefault()
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student/login`, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, password})
      })
      if (!res.ok) {
        throw new Error('Server error')
      }
      const data = await res.json()
      if (data?.error) {
        throw new Error(data.error)
      }
      if (decodeToken(data.token, props.setAuth, props.setToken)) {
        navigate('/student')
      }
    } catch (err) {
      console.error(err)
      setErrors(err.message)
    }

  }

  return (
    <div className="page login">
      <h2 className="pageTitle">Log in</h2>
      <form onSubmit={attemptLogin}>
        <LoginRow label="Email:" value={email} setFunc={setEmail}/>
        <LoginRow label="Password:" value={password} setFunc={setPassword} isPassword={true}/>
        <button type="submit">Submit</button>
        {errors && <p className="errorMessage">{errors}</p>}
      </form>

      <p>No account? <Link to="/student/register">Register</Link></p>
    </div>
  )
}