import React, {useState, useEffect} from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

export default function ManageAssignment(props) {
    const navigate = useNavigate();
    const { selectedAssignment, setSelectedSession, authorizedFetch } = useOutletContext();
    const [grades, setGrades] = useState([])
    
    useEffect(() => {
        async function fetchGrades() {
          const data = await authorizedFetch(
            `${process.env.REACT_APP_BACKEND_URL}/teacher/gradebook/${selectedAssignment.id}`,
          )
          setGrades(data.grades.map(prev => ({...prev, due_date: new Date(prev.due_date)})))
        }
    
        if (selectedAssignment) {
          fetchGrades()
        } else {
          navigate('/teacher/class')
        }
      }, [navigate, authorizedFetch, selectedAssignment])
    

    function selectSession(grade) {
      setSelectedSession(grade)
      navigate("/teacher/chatlog")
    }

    return (
    <div class="page">
      <button onClick={() => navigate("/teacher/class")}>Return to Class</button>
      <div class="teacherSection">
        <h2>{selectedAssignment?.name ? selectedAssignment.name : ''}</h2>
        <div>
          <div class="assignmentRow">
            <p class="classTitle"><b>Name</b></p>
            <p class="assignmentName"><b>Completion Status</b></p>
            <p class="assignmentCompletion"><b>Chatlog</b></p>
          </div>
          {grades.map(grade => (
              <div key={grade.student_id} class="assignmentRow">
                  <p class="classTitle">{grade.name}</p>
                  <p class="assignmentName">{grade.status}</p>
                  <p class="assignmentCompletion assignmentDescriptor" onClick={() => selectSession(grade)}>See Work</p>
                  <br/>
              </div>
          ))}
        </div>
      </div>
    </div>
    )
}