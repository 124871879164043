import React, { useState } from 'react';
import styles from '../styles/NewAssignment.module.css';

const SearchableDropdown = ({ options, onSelect, selectedValue }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = (option) => {
    onSelect(option);
    setSearchTerm(""); // Clear search term after selection
    setIsOpen(false); // Close the dropdown
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // If the input is cleared, we reset the selectedValue in the parent component
    if (value === "") {
      onSelect(""); // Reset selected topic in parent
    }
  };

  return (
    <div className={styles.dropdown}>
      <div className={styles.dropdownToggle} onClick={() => setIsOpen(!isOpen)}>
        <input
          type="text"
          placeholder="Search Topics..."
          value={searchTerm || selectedValue} // Show the selected value or search term
          onChange={handleInputChange} // Updated to handle input changes
          className={styles.searchInput}
        />
        <span className={styles.arrow}>&#9660;</span> {/* Custom arrow */}
      </div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className={styles.dropdownItem}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))
          ) : (
            <div className={styles.dropdownItem}>No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;

