import React, {useState, useEffect} from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import ClassRoster from "../components/ClassRoster";

export default function ManageClass(props) {
  const navigate = useNavigate();
  const { setSelectedAssignment, selectedClass, authorizedFetch } = useOutletContext();
  const [assignments, setAssignments] = useState([])

  const active = assignments.filter(e => e.due_date && e.due_date.getTime() > Date.now())
  const past = assignments.filter(e => !e.due_date || e.due_date.getTime() <= Date.now())

  function selectAssignment(assignmentObj) {
    setSelectedAssignment(assignmentObj)
    navigate("/teacher/assignment")
  }

  useEffect(() => {
    async function fetchAssignments() {
      const data = await authorizedFetch(
        `${process.env.REACT_APP_BACKEND_URL}/teacher/assignments/${selectedClass.id}`,
      )
      setAssignments(data.assignments.map(prev => ({...prev, due_date: new Date(prev.due_date)})))
    }

    if (selectedClass) {
      fetchAssignments()
    } else {
      navigate('/teacher')
    }
  }, [navigate, authorizedFetch, selectedClass])

  return (
    <div className="page">
      <h2 className="pageTitle">Class Home{selectedClass?.name ? ` - ${selectedClass.name}` : ''}</h2>
      <div className="teacherSection">
        <button onClick={() => navigate("/teacher/create")}>Create Assignment</button>
      </div>
      <div className="teacherSection">
        <h2 className="sectionHeader">Active Assignments</h2>
        <div className="assignmentRow">
          <p className="assignmentName"><b>Name</b></p>
          <p><b>Due Date</b></p>
        </div>
        {active && active.map((assignment, i) => <div
          key={i} className="assignmentRow" onClick={() => selectAssignment(assignment)}
        >
          <p className="assignmentName">{assignment.name}</p>
          <p>{assignment.due_date.toISOString()}</p>
          
        </div>)}
      </div>
      <div className="teacherSection">
        <h2 className="sectionHeader">Past Assignments</h2>
        <div className="assignmentRow">
          <p className="assignmentName"><b>Name</b></p>
          <p><b>Due Date</b></p>
        </div>

        {past && past.map((assignment, i) => <div
          key={i} className="assignmentRow" onClick={() => selectAssignment(assignment)}
        >
          <p className="assignmentName assignmentDescriptor">{assignment.name}</p>
          <p>{assignment.due_date.toISOString()}</p>
          
        </div>)}
      </div>
      <div className="teacherSection">
        {selectedClass && <ClassRoster classID={selectedClass.id}/>}
      </div>

    </div>
  )
}