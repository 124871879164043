import React, {useState, useEffect} from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

export default function TeacherPanel(props) {
  const navigate = useNavigate();
  const [classes, setClasses] = useState([])
  const { setSelectedClass, authorizedFetch } = useOutletContext();

  useEffect(() => {
    async function fetchClasses() {
      const data = await authorizedFetch(
        `${process.env.REACT_APP_BACKEND_URL}/classes`,
      )
      if (data?.classes) {
        setClasses(data.classes)
      }
    }
    fetchClasses()
  }, [authorizedFetch])

  function selectClass(classObj) {
    setSelectedClass(classObj)
    navigate("/teacher/class")
  }

  return (
    <div className="page">
      <h2 className="pageTitle">Teacher Home{props.auth?.name ? ` - ${props.auth.name}` : ''}</h2>
      {classes && classes.map((element, i) => <div 
        className="selectClass" key={i}
        onClick={() => selectClass(element)}
      >
        <p>{element.name}</p>
      </div>)}
    </div>
  )
}