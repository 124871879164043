import { Link } from "react-router-dom";

export default function Navbar(props) {
  return (
    <nav id="navbar">
      <Link to={`${props.loggedIn ? props.prefix : "/"}`} style={{ display: 'flex', alignItems: 'center' }}>
        <img src="/edengage logo.png" alt="EdEngage Logo" style={{ height: '40px', width: 'auto', marginRight: '8px' }} />
      </Link>
      <Link 
        to={`${props.prefix}/login`} 
        className="navButton" 
        onClick={() => {
          if (props.loggedIn) {
            props.setAuth(null);
            props.setToken(null);
            localStorage.removeItem("edengage_jwtToken");
          }
        }}
      >
        {props.loggedIn ? "Log Out" : "Login"}
      </Link>
    </nav>
  );
}