import React, {useState} from "react"
import { Link, useNavigate } from "react-router-dom"
import { decodeToken } from "../utils/auth";
import LoginRow from "../components/LoginRow";


export default function TeacherRegister(props) {
  const navigate = useNavigate();
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState(null)


  async function attemptRegister(e) {
    e.preventDefault()
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/teacher/register`, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({name, email, password})
      })
      const data = await res.json()
      if (data?.error) {
        throw new Error(data.error)
      }
      if (decodeToken(data.token, props.setAuth, props.setToken)) {
        navigate('/teacher')
      }
    } catch (err) {
      console.error(err)
      setErrors(err.message)
    }
  }

  return (
    <div className="page login">
      <h2 className="pageTitle">Register</h2>
      <form onSubmit={attemptRegister}>
        <LoginRow label="Name:" value={name} setFunc={setName}/>
        <LoginRow label="Email:" value={email} setFunc={setEmail}/>
        <LoginRow label="Password:" value={password} setFunc={setPassword} isPassword={true}/>
        <button type="submit">Submit</button>
        {errors && <p className="errorMessage">{errors}</p>}
      </form>
      <p>Already have an account? <Link to="/teacher/login">Log in</Link></p>
    </div>
  )
}