import React, { useState, useMemo } from "react"
import GraphManager from "./GraphManager";
import { variableNamesGenerator, objectOptions } from "../data/variableNames";

const defaultGraphProps = {
  xdomain: [-10, 10],
  ydomain: [-6, 6],
  width: 500, height: 300,
  xGridLines: 1, yGridLines: 1,
  axisLabels: [2, 2],
}

const AssignmentQuestion = (props) => {
  const {data, setData} = props;
  const [newObject, setNewObject] = useState("")
  const variableNames = variableNamesGenerator(props.curricula, data.objects)

  function addGraph() {
    setData(prev => ({
      ...prev,
      graphs: [...(prev.graphs ?? []), {...defaultGraphProps}] 
    }))
  }

  function modifyExistingGraph(func, i) {
    setData(prev => ({
      ...prev,
      graphs: [
        ...prev.graphs.slice(0, i),
        func(prev.graphs[i]),
        ...prev.graphs.slice(i + 1)
      ],
    }))
  }


  function addObject() {
    if (newObject !== "") {
      setData(prev => ({
        ...prev, 
        objects: {
          ...prev.objects,
          [newObject]: prev.objects[newObject] ? prev.objects[newObject] + 1 : 1
        }
      }))
      if (newObject === 'Graph') {
        addGraph()
      }
    }
  }

  function deleteObject(objectType, objectNumber) {
    setData(prev => {
      if (objectNumber < prev.objects[objectType] - 1) return prev
      return {
        ...prev,
        objects: {
          ...prev.objects,
          [objectType]: prev.objects[objectType] - 1,
        },
        input_values: prev.input_values.filter(
          (val, index) => !prev.inputs[index].startsWith(`${objectType}${objectNumber}`)
        ),
        inputs: prev.inputs.filter(val => !val.startsWith(`${objectType}${objectNumber}`))
      }
    })
    if (objectType === 'Graph') {
      setData(prev => ({
        ...prev,
        graphs: [
          ...prev.graphs.slice(0, objectNumber),
          ...prev.graphs.slice(objectNumber + 1)
        ],
      }))
    }
  }

  const objectNames = useMemo(() => {
    let out = []
    for (const [key, value] of Object.entries(data.objects)) {
      for (let i = 0; i < value; i++) {
        out.push({objectType: key, index: i})
      }
    }
    return out
  }, [data.objects])

  function deleteInput(index) {
    setData(prev => ({
      ...prev,
      inputs: [...prev.inputs.slice(0, index), ...prev.inputs.slice(index + 1)],
      input_values: [...prev.input_values.slice(0, index), ...prev.input_values.slice(index + 1)],
    }))
  }

  return <div className={`newQuestion flexCol ${props.done ? "saved" : "unsaved"}`}>
    <div className="flexRow questionComponent">
      <label>Question Prompt</label>
      <input value={data.prompt} 
        disabled={!setData}
        onChange={e => setData(prev => ({...prev, prompt: e.target.value}))} type="text"
        size={55}
      />
    </div>
    {objectNames.length > 0 && <div className="flexRow questionComponent">
      <label>Objects</label>
      {objectNames.map((e, i) => <div className="objectName flexRow" key={i}>
        {`${e.objectType}${e.index}`}
        {e.index === data.objects[e.objectType] - 1 && <button onClick={() => deleteObject(e.objectType, e.index)}>x</button>}
      </div>)}
    </div>}
    <div className="flexRow questionComponent">
      <select value={newObject} onChange={e => setNewObject(e.target.value)} style={{marginLeft: 0}}>
        <option value="">Select Object Type</option>
        {objectOptions.filter(e => props.validObjects.includes(e.value)).map((e, i) => <option key={i} value={e.value}>{e.label}</option>)}
      </select>
      <button onClick={addObject}>Add Object</button>      
    </div>
    {data.inputs.map((variable, i) => <div key={i} className="flexRow questionComponent">
      <label>Input Name</label>
      <select value={variable} 
        disabled={!setData}
        onChange={e => setData(prev => ({...prev, inputs: [
          ...prev.inputs.slice(0, i), e.target.value, ...prev.inputs.slice(i + 1)
        ]}))} type="text"
      >
        <option value="">Select Variable Name</option>
        {variableNames.map((element, index) => <option value={element} key={index}>{element}</option>)}
      </select>
      <label>Input Value</label>
      <input value={data.input_values[i]} 
        disabled={!setData}
        onChange={e => setData(prev => ({...prev, input_values: [
          ...prev.input_values.slice(0, i), e.target.value, ...prev.input_values.slice(i + 1)
        ]}))} type="text"
      />
      {i > 0 && <button onClick={() => deleteInput(i)}>x</button>}
    </div>)}
    {data.graphs && data.graphs.map((graphProps, i) => <GraphManager 
      key={i}
      graphProps={graphProps} setGraphProps={(func) => modifyExistingGraph(func, i)}
      
    />)}

    <button disabled={!setData} onClick={() => setData(prev => 
      ({...prev, inputs: [...prev.inputs, ''], input_values: [...prev.input_values, '']})
    )}>Add Variable</button>
    <div className="flexRow questionComponent" style={{width: "100%"}}>
      <label>Output</label>
      <select value={data.output} 
        disabled={!setData}
        onChange={e => setData(prev => ({...prev, output: e.target.value}))} type="text"
      >
        <option value="">Select Variable Name</option>
        {variableNames.map((element, index) => <option value={element} key={index}>{element}</option>)}
      </select>
      {props.done && <button className="deleteQuestionButton" onClick={props.deleteFunc}>Delete</button>}
    </div>

    <div>
      Given 
      <select value="">
        {variableNames.map((element, index) => <option value={element} key={index}>{element}</option>)}
      </select>
      , the student will find
      <select value={data.output}>
        {variableNames.map((element, index) => <option value={element} key={index}>{element}</option>)}
      </select>
    </div>
  </div>
}

export default AssignmentQuestion