import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

export default function ClassPage() {
  const { className } = useParams();  // Get class name from the URL
  const [assignments, setAssignments] = useState([]);
  const { setActiveAssignment, authorizedFetch } = useOutletContext();  // Use setActiveAssignment and authorizedFetch from context
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAssignments() {
      const data = await authorizedFetch(`${process.env.REACT_APP_BACKEND_URL}/student/assignments`);
      if (data?.assignments) {
        // Filter assignments to only those for the current class
        const classAssignments = data.assignments.filter(
          (assignment) => assignment.class_name === className
        );
        setAssignments(classAssignments);
      }
    }
    fetchAssignments();
  }, [className, authorizedFetch]);  // Depend on className for dynamic filtering

  // Handle assignment click just like in StudentHome
  function doAssignment(task) {
    setActiveAssignment(task);  // Set the active assignment
    navigate("/student/chat");  // Navigate to the assignment page
  }

  return (
    <div className="page classPage">
      <h2 className="pageTitle">Assignments for {className}</h2>

      {assignments.length > 0 ? (
        assignments.map((task, i) => (
          <div
            className="assignmentRow"
            onClick={() => doAssignment(task)}  // Add click handler here
            key={i}
          >
            <p className="assignmentName">{task.name}</p>
            <p className="assignmentDueDate">{new Date(task.due_date).toLocaleDateString()}</p>
            <p className="assignmentCompletion">
              {task.questions_done === task.num_questions
                ? 'Assignment Completed'
                : `In Progress (${task.questions_done}/${task.num_questions})`
              }
            </p>
          </div>
        ))
      ) : (
        <p>No assignments available for this class.</p>
      )}
    </div>
  );
}


