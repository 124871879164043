import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AssignmentQuestion from "../components/AssignmentQuestion";
import { structuredNames, curricula_ids } from "../data/variableNames";
import styles from '../styles/NewAssignment.module.css';
import topics from '../topics_list.json';
import SearchableDropdown from '../components/SearchableDropdown';

const defaultQuestionData = {
  prompt: "",
  objects: {},
  inputs: [""],
  input_values: [""],
  output: "",
}

function defaultDate() {
  const d = new Date();
  d.setHours(23)
  d.setMinutes(59)
  d.setSeconds(59)
  d.setMilliseconds(0)
  const dateTimeLocalValue = (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString()).slice(0, -1);
  return dateTimeLocalValue
}

export default function NewAssignment(props) {
  const { selectedClass, authorizedFetch, auth } = useOutletContext();
  const navigate = useNavigate()
  const [existingQuestions, setExistingQuestions] = useState([])
  const [name, setName] = useState("")
  const [curricula, setCurricula] = useState([])
  const [dueDate, setDueDate] = useState(defaultDate())
  const [numQuestions, setNumQuestions] = useState(0)
  const [errors, setErrors] = useState(null)
  const [filteredTopics] = useState(Object.keys(topics));
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedProblemTypes, setSelectedProblemTypes] = useState([]);

  
  function updateCurricula(e) {
    const newCurricula = Array.from(e.target.selectedOptions, i => i.value)
    const newValidObjects = newCurricula.map(i=> Object.keys(structuredNames[i])).flat()
    setCurricula(newCurricula)
    setExistingQuestions(prev => [...prev.map(entry => {
      const filtered = Object.fromEntries(
        Object.entries(entry.objects).filter(
            ([key, val]) => newValidObjects.includes(key)
        )
      ); 
      return {...entry, objects: filtered}
    })])
  }
  
  const validObjects = curricula.map(e => Object.keys(structuredNames[e])).flat()

  useEffect(() => {
    if (!selectedClass) {
      navigate('/teacher/class')
    }
  }, [navigate, selectedClass])

  function modifyExistingData(func, i) {
    setExistingQuestions(prev => [
      ...prev.slice(0, i), 
      func(prev[i]), 
      ...prev.slice(i + 1)
    ])
  }

  function addQuestion(data) {
    setExistingQuestions(prev => {
      setNumQuestions(prevNum => (prevNum === prev.length ? prev.length + 1 : prevNum))
      return [...prev, data]
    })
  }

  function deleteQuestion(i) {
    setExistingQuestions(prev => {
      setNumQuestions(prevNum => Math.min(prev.length - 1, prevNum))
      return [...prev.slice(0, i), ...prev.slice(i + 1)]
    })
  }

  async function createAssignment() {
    if (name === "") {
      setErrors("Assignment name must be non-empty")
      return
    }
    try {
      const body = {
        name,
        due_date: new Date(dueDate).toISOString().slice(0, 19).replace('T', ' '),
        num_questions: numQuestions,
        topic: topics[selectedTopic]['shortenedName'],
        problem_types: selectedProblemTypes,
        question_bank: existingQuestions, //get rid of existingQuestions throughout the file
        curricula_ids: curricula.map(e => curricula_ids[e])
      }
      await authorizedFetch(
        `${process.env.REACT_APP_BACKEND_URL}/teacher/newassignment/${selectedClass.id}`,
        "POST", body,
      )
      navigate('/teacher/class')
    } catch (err) {
      console.error(err)
      setErrors(err.message)
    }
  }

  const [teacherClasses, setTeacherClasses] = useState([]);

  useEffect(() => {
    async function fetchClasses() {
      const data = await authorizedFetch(
        `${process.env.REACT_APP_BACKEND_URL}/classes`
      );
      if (data?.classes) {
        setTeacherClasses(data.classes);
      }
    }
    fetchClasses();
  }, [authorizedFetch]);

  const handleTopicSelect = (topic) => {
    setSelectedProblemTypes([]);
    setSelectedTopic(topic);
  };

  const handleProblemTypeChange = (problemType) => {
    setSelectedProblemTypes((prevSelected) => {
      if (prevSelected.includes(problemType)) {
        // Remove it if it's already selected
        return prevSelected.filter((type) => type !== problemType);
      } else {
        // Add it if it's not already selected
        return [...prevSelected, problemType];
      }
    });
  };

  return (
    <div className={styles.page}>
      <div className={styles.welcomeBox}>
      <h3>Welcome, {auth?.name ? auth.name : "Teacher"}</h3>
        <p><b>Your Classes</b></p>
        <hr />
        <div className="classList">
          {teacherClasses.map((className, i) => (
            <div className="classButtonWrapper" key={i}>
              <button
                className="classButton"
                onClick={() => navigate(`/teacher/class`)} //take a look at this to navigate to the correct class when there are multiple. This is certainly wrong but works for now.
              >
                <span className="className">{className.name}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
  
      <div className={styles.contentWrapper}>
        <h2 className={styles.pageTitle}>New Assignment{selectedClass?.name ? ` - ${selectedClass.name}` : ''}</h2>

        <label className={styles.subtitle}>Topic:</label>
        <div className="flexRow assignmentPropRow">
          <SearchableDropdown
            options={filteredTopics}
            onSelect={handleTopicSelect}
            selectedValue={selectedTopic}
          />
        </div>

        <label className={styles.subtitle}>Subject Area:</label>
        {selectedTopic && (
          <div className="flexRow assignmentPropRow">
            <p>{topics[selectedTopic]?.description}</p>
          </div>
        )}

        <label className={styles.subtitle}>Example Question:</label>
        {selectedTopic && (
          <div className="flexRow assignmentPropRow">
            <p className={styles.exampleBox}>{topics[selectedTopic]?.example_question}</p>
          </div>
        )}

        <label className={styles.subtitle}>Problem Types:</label>
            <div className="flexColumn assignmentPropRow">
              {topics[selectedTopic]?.problem_types?.map((problemType, index) => (
                <div key={index} className="flexRow">
                  <input
                    type="checkbox"
                    id={`problemType-${index}`}
                    value={problemType}
                    checked={selectedProblemTypes.includes(problemType)}
                    onChange={() => handleProblemTypeChange(problemType)}
                  />
                  <label htmlFor={`problemType-${index}`}>{problemType}</label>
                </div>
              ))}
            </div>
  
        <label className={styles.subtitle}>Assignment Name:</label>
        <div className="flexRow assignmentPropRow">
          <input type="text" className="assignmentProp"
            value={name} onChange={(e) => setName(e.target.value)}
          />
        </div>

        <label className={styles.subtitle}>Set Due Date:</label>
        <div className="flexRow assignmentPropRow">
          <input type="datetime-local" className="assignmentProp"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}/>
        </div>

        <label className={styles.subtitle}>Number of Questions:</label>
        <div className="flexRow assignmentPropRow">
          <input type="number" className="assignmentProp"
            min={existingQuestions.length === 0 ? 0 : 1} 
            max={existingQuestions.length === 0 ? 0 : existingQuestions.length}
            value={numQuestions.toString()}
            onChange={e => setNumQuestions(parseInt(e.target.value))}
          />
        </div>
  
        {existingQuestions.map((e, i) => <AssignmentQuestion 
          key={i} data={e} done={true}
          setData={(func) => modifyExistingData(func, i)}
          deleteFunc={() => deleteQuestion(i)}
          curricula={curricula} validObjects={validObjects}
        />)}

        {/* <button className="actionButton" onClick={() => addQuestion({...defaultQuestionData})}>Add Question</button> */}

        <div className={styles.assignmentContainer}>
          <div className={styles.buttonContainer}>
            <button className={styles.actionButton} onClick={createAssignment}>
              Create
            </button>
          </div>
        </div>

  
        {errors && <p className="errorMessage">{errors}</p>}
      </div>
    </div>
  );  
}