import React, {useEffect, useState} from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import 'mathlive';
import Graph from "../components/Graph";
import RightTriangle from "../components/RightTriangle";
import AnswerBox from "../components/AnswerBox";
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import parse from 'html-react-parser';
import styles from '../styles/Chat.module.css';


export default function Chat(props) {
  const navigate = useNavigate()
  let [messages, setMessages] = useState([])
  let [answerBoxType, setAnswerBoxType] = useState("")
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [numQuestions, setNumQuestions] = useState(0)
  const [newMessage, setNewMessage] = useState("")
  const {activeAssignment, authorizedFetch} = useOutletContext()
  const assignmentId = activeAssignment?.assignment_id
  const [isStreaming, setIsStreaming] = useState(false)

  //messages = messages.slice(0, -1)

  useEffect(() => {
    async function getPrompt() {
      const data = await authorizedFetch(
        `${process.env.REACT_APP_BACKEND_URL}/chat/${assignmentId}/${currentQuestion}`,
      )
      setMessages(data.messages)
      setAnswerBoxType(data.headers)
      setCurrentQuestion(data.current_question)
      setNumQuestions(data.num_questions)
    }
    if (assignmentId !== undefined) {
      getPrompt()
    } else {
      navigate('/student')
    }
  }, [authorizedFetch, navigate, assignmentId, currentQuestion])

  const pastMessagesRef = React.useRef(null);
  useEffect(() => {
    // Scroll to the last message after messages have changed
    if (pastMessagesRef.current) {
      pastMessagesRef.current.scrollTop = pastMessagesRef.current.scrollHeight;
    }
  }, [messages]);

  function prevQuestion() {
    if (currentQuestion <= 1) {
      return;
    }
    setCurrentQuestion(currentQuestion - 1);
    setMessages([]);
  }
  
  function nextQuestion() {
    if (currentQuestion >= numQuestions) {
      return;
    }
    setCurrentQuestion(currentQuestion + 1);
    setMessages([]);
  }
  

  async function sendMessage(user_message, route='note', isExpression=false) {
    
    const cleaned_message = user_message.replaceAll('\\right', '').replaceAll('\\left', '')
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${route}/${assignmentId}/${currentQuestion}`, {
      headers: {'Authorization': props.token},
      method: "POST",
      body: cleaned_message
    })
    
    const mathed_message = isExpression ? `\\(${user_message}\\)` : user_message
    

    if (response.headers.get('content-type') === 'is_stream') {
      setIsStreaming(true)
      setMessages(prev => [
        ...prev,
        {contents: mathed_message, fromSelf: true, timestamp: new Date()}
      ])

      //this is all just to stream the bot's chat response
      var tmpPromptResponse = '';
      let decoder = new TextDecoderStream();
      
      //if (!response.body) return;
      const reader = response.body.pipeThrough(decoder).getReader();
      let chatCreated = false;
      while (true) {
        var {value, done} = await reader.read();
        if (done) {
          chatCreated = false;
          break;
        } else {
          tmpPromptResponse += value;
  
          if (chatCreated === false) {
            chatCreated = true;
            //this splits the response into multiple messages if it contains the string '//next_chat//', which delimits messages
            if (value.includes('//next_chat//')) {
              // eslint-disable-next-line no-loop-func
              setMessages(prev => [
                ...prev,
                ...tmpPromptResponse.split('//next_chat//').map((chat, i) => {
                  return {contents: chat, fromSelf: false, timestamp: new Date()}
                })
              ])
            } else {
              // eslint-disable-next-line no-loop-func
              setMessages(prev => [
                ...prev,
                {contents: tmpPromptResponse, fromSelf: false, timestamp: new Date()}
              ])
            }
          } else {
            // eslint-disable-next-line no-loop-func
            setMessages(prev => [
              ...prev.slice(0, -1),
              {contents: tmpPromptResponse, fromSelf: false, timestamp: new Date()}
            ])
          }
        }
      }
    } else {
      let data = await response.json();

      setMessages(prev => [
        ...prev,
        {contents: mathed_message, fromSelf: true, timestamp: new Date()},
        ...data['messages']
      ]);
      setAnswerBoxType(data['headers'])
    }

    // console.error('cool')

    // let data = await response.json()
    // //let structuredInput = data['structuredInput']
    // data = data['messages']

    // // if (structuredInput) {
    // //   let newMessageBox = document.getElementsByClassName("newMessageBox")[0]
    // //   newMessageBox.innerHTML = '<input type="text" class="newMessage" value="" placeholder="Numeric Answer">'
    // // }

    // setMessages(prev => [
    //   ...prev,
    //   {contents, fromSelf: true, timestamp: new Date()},
    //   ...data
    // ])
    //props.setActiveAssignment(data.assignment)
    setIsStreaming(false)

  }
  function submitMessage() {
    sendMessage(newMessage)
    setNewMessage("")
  }

  function checkForLatex(message) {
    if (message.includes('\\(') || message.includes('$$')) {
      return <Latex>{message.replaceAll('$$', '$').replaceAll('\\\\', '\\')}</Latex>
    }
    return parse(message)
  }

  function renderMessageContents(contents) {
    const graphJSON = contents.includes("GRAPHJSON{") && contents.split("GRAPHJSON")[1].split('\n')[0].replaceAll("'", '"')
    const graphProps = graphJSON && JSON.parse(graphJSON)
    //if (graphProps) console.log(graphProps)
    let table = contents.includes("TABLE[[") && contents.split("TABLE[[")[1].split('\n')[0]
    let triangle_visual = contents.includes("TRIANGLEVISUAL{") && contents.split("TRIANGLEVISUAL")[1].split('\n')[0]
    if (graphProps) {
      const arrayProps = ["xdomain", "ydomain", "axisLabels"]
      for (const arrayProp of arrayProps) {
        if (graphProps[arrayProp]) {
          graphProps[arrayProp] = JSON.parse(graphProps[arrayProp])
        }
      }
      let before = contents.split("GRAPHJSON")[0]
      let after = contents.split("GRAPHJSON")[1].split('\n').slice(1).join("\n")
      return <div className="message">
        {checkForLatex(before)}
        {<Graph {...graphProps}/>}
        {checkForLatex(after)}
      </div>
    } else if (table) {
      table = JSON.parse('[[' + table)
      let before = contents.split("TABLE[[")[0]
      let after = contents.split("TABLE[[")[1].split('\n').slice(1).join("\n")
      return <div className="message">
        {checkForLatex(before)}
        <table><tbody>
          <tr>
            <td className="pointTableCell">X values</td>
            {Array(table[0].length).fill().map((_, i) => <td key={i} className='pointTableCell'>
              {table[0][i]}
            </td>)}
          </tr>
          <tr>
            <td className="pointTableCell">Y values</td>
            {Array(table[1].length).fill().map((_, i) => <td key={i} className='pointTableCell'>
              {table[1][i]}
            </td>)}
          </tr>
        </tbody></table> 
        {checkForLatex(after)}
      </div>
    } else if (triangle_visual) {
      let before = contents.split("TRIANGLEVISUAL{")[0]
      let after = contents.split("TRIANGLEVISUAL{")[1].split('\n').slice(1).join("\n")
      return <div className="message">
        {checkForLatex(before)}
        {<RightTriangle/>}
        {checkForLatex(after)}
      </div>
    }
    return <div className="message">
      {checkForLatex(contents)}
    </div>
  }

  return (
    <div className="page chat">
      <div className="assignmentHeader">
        <h2 className="pageTitle">{activeAssignment?.name}</h2>
        {/*<p>Questions done: {activeAssignment?.questions_done} out of {activeAssignment?.total_questions}</p>*/}
        <p style={{ color: 'DimGrey' }}>Do not share any personal data with the chatbot. Your chat history can be viewed by your teacher or EdEngage.</p>
      </div>
      <div className="messageWrapper">
        <div className="pastMessages" ref={pastMessagesRef}>
        {messages.map((message, i ) => <div key={i}
          className={`messageRow ${message.fromSelf ? "fromSelf" : "fromServer"}`}
        >
          {renderMessageContents(message.contents)}
          {/*<p>{message.timestamp?.toString()}</p>*/}
        </div>)}
        <div id='scroll-anchor'></div>
        </div>
        <div className="newMessageBox flexCol">
          {answerBoxType && answerBoxType !== "false" && <AnswerBox disabled={isStreaming} type={answerBoxType} sendMessage={(message, isExpression) => sendMessage(message, "message", isExpression)}/>}
          <div className="flexRow chatInputRow" id="chatBox">
            <label style={{fontSize: "1.2em"}}>Chat with Eden:</label>
            <textarea type="text" className="newMessage"
            value={newMessage} onChange={(e) => setNewMessage(e.target.value)}
            />
            <button onClick={submitMessage} disabled={isStreaming}>Send Message</button>
          </div>
        </div>
      </div>
      <div className={styles.navigationButtons}>
        <button className={`${styles.navButton} ${styles.prevButton}`} onClick={prevQuestion}>Previous</button>
        <button className={`${styles.navButton} ${styles.nextButton}`} onClick={nextQuestion}>Next</button>
      </div>
      <p>Current Question: {currentQuestion} of {numQuestions}</p>
    </div>
  )
}