import React, {useState} from "react"
import Graph from "./Graph"

const colors = ["red", "green", "blue", "black", "orange", "purple"]

const gridLineOptions = [
  {value: 1, label: "Integer"},
  {value: 0.5, label: "Half-integer"},
  {value: 1/3, label: "1/3"},
  {value: 1/4, label: "1/4"},
  {value: 1/5, label: "1/5"},
  {value: 1/6, label: "1/6"},
  {value: 1/8, label: "1/8"},
]

const parseDomainInput = (val) => val === '' || isNaN(val) ? 0 : parseFloat(val)

const parseAllowBad = (e) => (e.target.value === '' || isNaN(e.target.value)) ? e.target.value : parseFloat(e.target.value)

function NewLineInput(props) {
  const [slope, setSlope] = useState(0)
  const [yInt, setYInt] = useState(0)

  return <div className="flexCol lineInput">
    <div className="flexRow">
      <label>Slope</label>
      <input type="number"
        value={String(slope)}
        onChange={e => setSlope(parseDomainInput(e.target.value))}
      />
    </div>
    <div className="flexRow">
      <label>Y-intercept</label>
      <input type="number"
        value={String(yInt)}
        onChange={e => setYInt(parseDomainInput(e.target.value))}
      />
    </div>
    <button onClick={() => props.submit(slope, yInt)}>Add Line</button>
  </div>
}


// {start: [-7, false], stop: [-5, true], funcType: "linear", funcParams: [-2, 9]}

function PiecewiseSegmentInput(props) {
  const {data, setData} = props;
  const [editing, setEditing] = useState("")

  function changeFuncType(newType) {
    const defaultParams = newType === "linear" ? [0, 0] // y = 0
      : newType === "quadratic" ? [1, 0, 0] // y = x^2 + 0x + 0
      : [1, 2] // y = 1 * 2^x
    setData(prev => ({
      ...prev,
      funcType: newType,
      funcParams: defaultParams
    }))
  }

  function setFuncParam(e, i) {
    setData(prev => ({...prev,
      funcParams: [
        ...prev.funcParams.slice(0, i), 
        parseAllowBad(e), 
        ...prev.funcParams.slice(i + 1)
      ]
    }))
  }

  return <div className="flexRow">
    {editing === "function" ? <>
      <label>Function Type</label>
      <select value={data.funcType} onChange={(e) => changeFuncType(e.target.value)}>
        <option value="linear">Linear</option>
        <option value="quadratic">Quadratic</option>
        <option value="exponential">Exponential</option>
      </select>
      {data.funcType === 'linear' ? <>
        <label>Slope</label>
        <input type="text" value={data.funcParams[0]} onChange={(e) => setFuncParam(e, 0)}/>
        <label>Y-intercept</label>
        <input type="text" value={data.funcParams[1]} onChange={(e) => setFuncParam(e, 1)}/>
      </> : data.funcType === 'quadratic' ? <>
        <label>a (coefficient of x^2)</label>
        <input type="text" value={data.funcParams[0]} onChange={(e) => setFuncParam(e, 0)}/>
        <label>b (coefficient of x)</label>
        <input type="text" value={data.funcParams[1]} onChange={(e) => setFuncParam(e, 1)}/>
        <label>c (y-int)</label>
        <input type="text" value={data.funcParams[2]} onChange={(e) => setFuncParam(e, 2)}/>
      </> : <>
        <label>Coefficient</label>
        <input type="text" value={data.funcParams[0]} onChange={(e) => setFuncParam(e, 0)}/>
        <label>Exponential base</label>
        <input type="text" value={data.funcParams[1]} onChange={(e) => setFuncParam(e, 1)}/>
      </>}
      <button onClick={() => setEditing("")}>Done</button>
    </> : <>
      <label>
        y = {data.funcType === "linear" ? `${data.funcParams[0]}x + ${data.funcParams[1]}` 
          : data.funcType === "quadratic" ? `${data.funcParams[0]}x^2 + ${data.funcParams[1]}x + ${data.funcParams[2]}`
          : `${data.funcParams[0]} * ${data.funcParams[1]}^x`
        }
      </label>
      <button onClick={() => setEditing("function")}>Edit Function</button>
    </>}

    {editing === "domain" ? <>
      <label>Start</label>
      <input type="text"
        value={data.start[0]}
        onChange={(e) => setData(prev => ({...prev, start: [parseAllowBad(e), prev.start[1]]}))}
      />
      {data.start[0] !== "-inf" && <select 
        value={data.start[1]}
        onChange={(e) => setData(prev => ({...prev, start: [prev.start[0], e.target.value]}))}
      >
        <option value={true}>Inclusive</option>
        <option value={false}>Not inclusive</option>
      </select>}
      <label>Stop</label>
      <input type="text"
        value={data.stop[0]}
        onChange={(e) => setData(prev => ({...prev, stop: [parseAllowBad(e), prev.stop[1]]}))}
      />
      {data.stop[0] !== "inf" && <select 
        value={data.stop[1]}
        onChange={(e) => setData(prev => ({...prev, stop: [prev.stop[0], e.target.value]}))}
      >
        <option value={true}>Inclusive</option>
        <option value={false}>Not inclusive</option>
      </select>}
      <button onClick={() => setEditing("")}>Done</button>
    </> : <>
      <label>
        {data.start[0] === "-inf" ? "" : `${data.start[0]} ${data.start[1] ? '≤' : '<'} `}
        x
        {data.stop[0] === "inf" ? "" : ` ${data.stop[1] ? '≤' : '<'} ${data.stop[0]}`}
      </label>
      <button onClick={() => setEditing("domain")}>Edit Domain</button>
    </>}

   </div>
  
}

function NewPiecewiseInput(props) {
  const [segments, setSegments] = useState([])
  const [errors, setErrors] = useState("")

  function setSegmentData(func, i) {
    setSegments(prev => [
      ...prev.slice(0, i),
      func(prev[i]),
      ...prev.slice(i + 1)
    ])
  }

  function validateSegments() { 
    // Check for NaNs and invalid domains 
    if (segments.length === 0) {
      setErrors("No segments in the function")
      return false 
    }
    for (let i = 0; i < segments.length; i++) {
      if (segments[i].start[0] !== '-inf' && isNaN(segments[i].start[0])) {
        setErrors(`Invalid starting x value for segment ${i}`)
      }
      if (segments[i].stop[0] !== 'inf' && isNaN(segments[i].stop[0])) {
        setErrors(`Invalid ending x value for segment ${i}`)
      }
    }
    return true 
  }

  function addSegment() {
    if (segments.length > 0 && segments[segments.length - 1].stop[0] === "inf") {
      setErrors("Entire domain (-inf, inf) filled")
    } else {
      const lastStop = segments.length > 0 ? segments[segments.length - 1].stop : ['-inf', true]
      setSegments(prev => [...prev, {
        start: [lastStop[0], !lastStop[1]],
        stop: ['inf', false],
        funcType: 'linear',
        funcParams: [0, 0]
      }])
      setErrors("")
    }
  }

  function submitPiecewise() {
    // perhaps do a bounds check (is the function a valid function?) (is its domain (-inf, inf)?)
    // check if domains are valid 
    if (validateSegments()) {
      props.submit(segments)
      setSegments([])
      setErrors("")
    }
  }

  return <div className="flexCol">
    <button onClick={addSegment}>Add Segment</button>
    {segments.map((seg, i) => <PiecewiseSegmentInput
      data={segments[i]}
      setData={(func) => setSegmentData(func, i)}
    />)}
    {errors.length > 0 && <p className="errorMessage">{errors}</p>}
    {segments.length > 0 && <button onClick={submitPiecewise}>Add Piecewise</button>}
  </div>
}

function GraphManager(props) {
  const [adding, setAdding] = useState("")
  const [segmentStart, setSegmentStart] = useState(null)
  const [snapPoints, setSnapPoints] = useState(false)
  const [mouseCoords, setMouseCoords] = useState([0, 0])

  const pointCount = props.graphProps?.points?.length ?? 0
  const lineCount = props.graphProps?.lines?.length ?? 0
  const segmentCount = props.graphProps?.lineSegments?.length ?? 0
  const piecewiseCount = props.graphProps?.piecewiseFuncs?.length ?? 0
  const newColor = colors[(pointCount + lineCount + segmentCount + piecewiseCount) % colors.length]

  function addButton(objectType) {
    setSnapPoints(true)
    setAdding(prev => prev === objectType ? "" : objectType)
  }
  
  function addPoint(newPoint) {
    if (!isFinite(newPoint.x) || !isFinite(newPoint.y)) {
      return;
    }
    props.setGraphProps(prev => ({...prev, points: [...(prev.points ?? []), {...newPoint, color: newColor}]}))
  }

  function addSegment(endPoint) {
    props.setGraphProps(prev => ({
      ...prev, 
      lineSegments: [...(prev.lineSegments ?? []), {start: segmentStart, stop: endPoint, color: newColor}]
    }))
    setSegmentStart(null)
  }

  function createNewLine(m, b) {
    props.setGraphProps(prev => ({
      ...prev,
      lines: [...(prev.lines ?? []), {m, b, color: newColor}]
    }))
  }

  function createNewPiecewise(segments) {
    props.setGraphProps(prev => ({
      ...prev,
      piecewiseFuncs: [...(prev.piecewiseFuncs ?? []), {segments, color: newColor}]
    }))
  }

  function onMouseMove(e) {
    let offset = e.target.getBoundingClientRect();
    if (offset.width > 0 && offset.height > 0) {
      let xdomain = props.graphProps.xdomain
      let ydomain = props.graphProps.ydomain
      const x = (e.clientX - offset.left)/(offset.width)*(xdomain[1] - xdomain[0]) + xdomain[0]
      const y = (offset.height - (e.clientY - offset.top))/(offset.height)*(ydomain[1] - ydomain[0]) + ydomain[0]
      setMouseCoords([x, y])
    }
  }

  function onClick(e) {
    let snappedX = Math.round(mouseCoords[0] / props.graphProps.xGridLines) * props.graphProps.xGridLines
    let snappedY = Math.round(mouseCoords[1]/ props.graphProps.yGridLines) * props.graphProps.yGridLines
    let clickPoint = {x: snapPoints ? snappedX : mouseCoords[0], y: snapPoints ? snappedY : mouseCoords[1]}
    if (adding === "point") {
      addPoint(clickPoint)
    } else if (adding === "segment") {
      let listPoint = [clickPoint.x, clickPoint.y]
      if (segmentStart === null) {
        setSegmentStart(listPoint)
      } else {
        addSegment(listPoint)
      }
    }
  }

  return <div className="flexCol graphManager">
    <div className="flexRow" style={{alignItems: "center"}}>
      <Graph 
        {...props.graphProps} 
        onMouseMove={onMouseMove}
        onClick={onClick}
      />
      <div className="flexCol">
        {(adding === "" || adding === "point") && <button onClick={() => addButton("point")}>
          {adding === "point" ? "Stop adding points" : "Add points"}
        </button>}
        {(adding === "" || adding === "segment") && <button onClick={() => addButton("segment")}>
          {`${adding === "segment" ? "Stop adding" : 'Add'} line segments`}
        </button>}
        {(adding === "" || adding === "line") && <button onClick={() => addButton("line")}>
          {`${adding === "line" ? "Stop adding" : 'Add'} lines`}
        </button>}
        {(adding === "" || adding === "piecewise") && <button onClick={() => addButton("piecewise")}>
          {`${adding === "piecewise" ? "Stop adding" : 'Add'} piecewise functions`}
        </button>}
        {(adding === "point" || adding === "segment") && <button 
          onClick={() => setSnapPoints(prev => !prev)}
        >
          {`${snapPoints ? "S" : "Not s"}napping points to grid`}
        </button>}
        {(adding === "line") && <NewLineInput submit={createNewLine}/>}
        {(adding === "piecewise") && <NewPiecewiseInput submit={createNewPiecewise}/>}
      </div>
    </div>
    <div className="flexRow">
      <label>X-Axis grid lines</label>
      <select className="paddedInput" 
        value={String(props.graphProps.xGridLines)} 
        onChange={e => props.setGraphProps(prev => ({...prev, xGridLines: parseFloat(e.target.value)}))}
      >
        {gridLineOptions.map((e, i) => <option value={e.value} key={i}>{e.label}</option>)}
      </select>
      <label>Label every</label>
      <input type='number' className="paddedInput" 
        value={props.graphProps.axisLabels[0]}
        min={0}
        onChange={(e) => props.setGraphProps(prev => ({
          ...prev, 
          axisLabels: [parseDomainInput(e.target.value), prev.axisLabels[1]]
        }))}
      />
      <label>lines</label>
    </div>
    <div className="flexRow">
      <label>Y-Axis grid lines</label>
      <select className="paddedInput" 
        value={String(props.graphProps.yGridLines)} 
        onChange={e => props.setGraphProps(prev => ({...prev, yGridLines: parseFloat(e.target.value)}))}
      >
        {gridLineOptions.map((e, i) => <option value={e.value} key={i}>{e.label}</option>)}
      </select>
      <label>Label every</label>
      <input type='number' className="paddedInput"
        value={props.graphProps.axisLabels[1]}
        min={0}
        onChange={(e) => props.setGraphProps(prev => ({
          ...prev, 
          axisLabels: [prev.axisLabels[0], parseDomainInput(e.target.value),]
        }))}
      />
      <label>lines</label>
    </div>
    <div className="flexRow">
      <label style={{marginRight: 8}}>Domain</label>
      <input type="number" placeholder="Start" value={props.graphProps?.xdomain[0]}
        onChange={(e) => {
          const newVal = parseDomainInput(e.target.value)
          props.setGraphProps(prev => ({
            ...prev, 
            xdomain: [newVal, prev.xdomain[1]],
            width: prev.width * (prev.xdomain[1] - newVal) / (prev.xdomain[1] - prev.xdomain[0]) 
          }))
        }}
      />
      <input type="number" placeholder="Stop" value={props.graphProps?.xdomain[1]}
        onChange={(e) => {
          const newVal = parseDomainInput(e.target.value)
          props.setGraphProps(prev => ({
            ...prev, 
            xdomain: [prev.xdomain[0], newVal],
            width: prev.width * (newVal - prev.xdomain[0]) / (prev.xdomain[1] - prev.xdomain[0]) 
          }))
        }}
      />
    </div>
    <div className="flexRow">
      <label style={{marginRight: 8}}>Range</label>
      <input type="number" placeholder="Start" value={props.graphProps?.ydomain[0]}
        onChange={(e) => {
          const newVal = parseDomainInput(e.target.value)
          props.setGraphProps(prev => ({
            ...prev, 
            ydomain: [newVal, prev.ydomain[1]],
            height: prev.height * (prev.ydomain[1] - newVal) / (prev.ydomain[1] - prev.ydomain[0]) 
          }))
        }}
      />
      <input type="number" placeholder="Stop" value={props.graphProps?.ydomain[1]}
        onChange={(e) => {
          const newVal = parseDomainInput(e.target.value)
          props.setGraphProps(prev => ({
            ...prev, 
            ydomain: [prev.ydomain[0], newVal],
            height: prev.height * (newVal - prev.ydomain[0]) / (prev.ydomain[1] - prev.ydomain[0]) 
          }))
        }}
      />
    </div>
  </div>
} 

export default GraphManager