import {decodeJwt} from "jose";

export function decodeToken(tokenString, setAuth, setToken) {
  const claims = decodeJwt(tokenString)
  if (claims.exp > Date.now() / 1000) {
    localStorage.setItem("edengage_jwtToken", tokenString);
    setToken(tokenString)
    setAuth(claims)
    return true
  }
  return false
}