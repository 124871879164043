import vars from './variables.json'

export const structuredNames = vars.vars
export const wordized_terms = vars.wordized_terms

export const curricula_ids = {
  "nc_math_8": 1,
  "algebra": 2,
}

// export const structuredNames = {
//   "nc_math_8": {
//     "LinEq": [
//       "y_intercept",
//       "slope",
//       "equation",
//       "graph",
//       "linearity",
//       "sample_table",
//     ],
//     "Points": [
//       "table",
//       "linearity",
//       "model_equation_varcode",
//       "is_function",
//       "is_linear"
//     ],
//     "Graph": [
//       'graph'
//     ],
//   },
//   "algebra": {
//     "Equation": [
//       "equation"
//     ],
//   }
// }

export const objectOptions = [
  {value: "Points", label: "Points Set"},
  {value: "LinEq", label: "Linear Equation"},
  {value: "Graph", label: "Graph"},
  {value: "Equation", label: "Algebra Equation"},
]

export function variableNamesGenerator(curricula) {
  let out = []
  for (const curriculum of curricula) {
    for (const objectType in structuredNames[curriculum]) {
      for (const variable of structuredNames[curriculum][objectType]) {
        out.push(`${wordized_terms[variable] ?? variable} of a ${wordized_terms[objectType]}`)
      }
    }
  }
  return out
}