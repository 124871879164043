import React, {useState, useEffect} from "react"
import { useOutletContext } from "react-router-dom"

export default function ClassRoster(props) {
  const {authorizedFetch} = useOutletContext()
  const [students, setStudents] = useState([])

  useEffect(() => {
    async function fetchStudents() {
      const data = await authorizedFetch(
        `${process.env.REACT_APP_BACKEND_URL}/students/${props.classID}`,
      )
      setStudents(data.students)
    }
    fetchStudents()
  }, [authorizedFetch, props.classID])

  return (
    <div className="classRoster">
      <h2 className="sectionHeader">Class Roster</h2>
      {students && students.map((student, i) => <div key={i}>
        <p>{student.name}</p>
      </div>)}
    </div>
  )
}