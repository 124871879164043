import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";

import './App.css';
import Chat from "./pages/Chat";
import TeacherPanel from "./pages/TeacherPanel";
import StudentLogin from "./pages/StudentLogin";
import StudentRegister from "./pages/StudentRegister";
import TeacherLogin from "./pages/TeacherLogin";
import TeacherRegister from "./pages/TeacherRegister";
import Rosters from "./pages/Rosters";
import NewAssignment from "./pages/NewAssignment";
import Home from "./pages/Home";
import StudentHome from "./pages/StudentHome";
import ClassPage from "./pages/ClassPage";  // <-- Import ClassPage component
import StudentApp from "./components/StudentApp";
import TeacherApp from "./components/TeacherApp";

import { decodeToken } from "./utils/auth";
import ManageClass from "./pages/ManageClass";
import ManageAssignment from "./pages/ManageAssignment";
import ViewChats from "./pages/ViewChats";


function App() {
  const [auth, setAuth] = useState(null);
  const [token, setToken] = useState(null);

  const userID = auth?.user_id;

  useEffect(() => {
    if (localStorage.edengage_jwtToken) {
      decodeToken(localStorage.edengage_jwtToken, setAuth, setToken);
    }
  }, []);

  return (
    <BrowserRouter id="app">
      <Routes>
        <Route path="/" element={<Home />} />
        
        {/* Student routes */}
        <Route path="/student" element={<StudentApp
          auth={auth}
          setAuth={setAuth}
          token={token}
          setToken={setToken}
        />}>
          <Route path="" element={<StudentHome auth={auth} />} />
          
          <Route path="login" element={<StudentLogin
            userID={userID}
            setAuth={setAuth}
            setToken={setToken}
          />} />
          
          <Route path="register" element={<StudentRegister
            userID={userID}
            setAuth={setAuth}
            setToken={setToken}
          />} />
          
          <Route path="chat" element={<Chat token={token} />} />

          {/* New Route: ClassPage for showing assignments in a specific class */}
          <Route path="class/:className" element={<ClassPage />} />
        </Route>

        {/* Teacher routes */}
        <Route path="/teacher" element={<TeacherApp
          auth={auth}
          setAuth={setAuth}
          token={token}
          setToken={setToken}
        />}>
          <Route path="" element={<TeacherPanel auth={auth} />} />
          <Route path="class" element={<ManageClass />} />
          <Route path="assignment" element={<ManageAssignment />} />
          <Route path="chatlog" element={<ViewChats />} />
          
          <Route path="login" element={<TeacherLogin
            token={token}
            setAuth={setAuth}
            setToken={setToken}
          />} />
          
          <Route path="register" element={<TeacherRegister
            token={token}
            setAuth={setAuth}
            setToken={setToken}
          />} />
          
          <Route path="rosters" element={<Rosters />} />
          <Route path="create" element={<NewAssignment />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
