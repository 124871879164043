import React from "react"

function labelHelper(spacer, domain) {
  let positiveLabels = []
  let negativeLabels = []
  if (domain[0] < 0 && domain[1] > 0) {
    const posCount = Math.floor(domain[1] / spacer)
    const negCount = Math.floor(Math.abs(domain[0]) / spacer)
    positiveLabels = [...Array(posCount).keys()].map(e => (e + 1) * spacer)
    negativeLabels = [...Array(negCount).keys()].map(e => (e + 1) * spacer * -1)
  } else if (domain[1] > 0) {
    const posCount = Math.floor((domain[1] - domain[0]) / spacer)
    positiveLabels = [...Array(posCount).keys()].map(e => (e + 1) * spacer + domain[0])
  } else if (domain[0] < 0) {
    const negCount = Math.floor((domain[1] - domain[0]) / spacer)
    negativeLabels = [...Array(negCount).keys()].map(e => (e + 1) * spacer * -1 + domain[1])
  } 
  return [...positiveLabels, ...negativeLabels]
}

const roundTo3Places = x => Math.round(x * 1000) / 1000

function Graph(props) {
  const width = props.width ?? 500
  const height = props.height ?? 300
  const ydomain = props.ydomain ?? [-6, 6]
  const xdomain = props.xdomain ?? [-10, 10]
  if (isNaN(height) || isNaN(width) || ydomain[1] <= ydomain[0]
     || xdomain[1] <= xdomain[0]) return <div/>
  const xGridLines = props.xGridLines ?? 1
  const yGridLines = props.yGridLines ?? 1
  //const axisLabels = props.axisLabels ?? [2, 2]
  let points = props.points ?? []
  let lineSegments = props.lineSegments ?? []
  const lines = props.lines ?? []
  const shapes = props.shapes ?? []
  const piecewiseFuncs = props.piecewiseFuncs ?? []

  if (shapes != []) {
    for (let i = 0; i < shapes.length; i++) {
      for (let j = 0; j < shapes[i].length; j++) {
        points.push({'x': shapes[i][j][0], 'y': shapes[i][j][1], 'color': 'blue'})
        lineSegments.push({'start': shapes[i][j], 'stop': shapes[i][(j + 1) % shapes[i].length], 'color': 'blue'})
      }
    }
  }

  const pixelWidth = (ydomain[1] - ydomain[0])/height
  const axisLabels = [
    Math.ceil(roundTo3Places(50 / (xGridLines / pixelWidth))),
    Math.ceil(roundTo3Places(50 / (yGridLines / pixelWidth)))
  ]

  function transformY(yCoord) {
    return ydomain[0] + ydomain[1] - yCoord
  }

  const borders = [
    {x1: xdomain[0], y1: transformY(ydomain[0]), x2: xdomain[1], y2: transformY(ydomain[0])},
    {x1: xdomain[0], y1: transformY(ydomain[1]), x2: xdomain[1], y2: transformY(ydomain[1])},
    {x1: xdomain[0], y1: transformY(ydomain[0]), x2: xdomain[0], y2: transformY(ydomain[1])},
    {x1: xdomain[1], y1: transformY(ydomain[0]), x2: xdomain[1], y2: transformY(ydomain[1])},
  ]

  const axes = [
    {x1: 0, y1: transformY(ydomain[0]), x2: 0, y2: transformY(ydomain[1])},
    {x1: xdomain[0], y1: transformY(0), x2: xdomain[1], y2: transformY(0)}
  ]

  const firstXLine = Math.ceil(xdomain[0] / xGridLines) * xGridLines 
  const lastXLine = Math.floor(xdomain[1] / xGridLines) * xGridLines 

  const firstYLine = Math.ceil(ydomain[0] / yGridLines) * yGridLines
  const lastYLine =  Math.floor(ydomain[1] / yGridLines) * yGridLines 

  const xIntegerVals = [...Array((lastXLine - firstXLine) / xGridLines + 1).keys()].map(e => e * xGridLines + firstXLine)
  const yIntegerVals = [...Array((lastYLine - firstYLine) / yGridLines + 1).keys()].map(e => e * yGridLines + firstYLine)

  const xAxisLabels = () => {
    if (axisLabels[0] === 0) return []
    const spacer = xGridLines * axisLabels[0]
    let y = ydomain[1] <= 0 ? ydomain[1] : ydomain[0] >= 0 ? ydomain[0] + pixelWidth * 16 : 0 
    return labelHelper(spacer, xdomain).map((x, i) => <text 
      fontSize={pixelWidth * 12} key={i} 
      x={x} y={transformY(y - pixelWidth * 8)}
      dominantBaseline="middle" textAnchor="middle"
    >
      {roundTo3Places(x)}
    </text>)
  }

  const yAxisLabels = () => {
    if (axisLabels[1] === 0) return []
    const spacer = yGridLines * axisLabels[1]
    let x = xdomain[1] <= 0 ? xdomain[1] : xdomain[0] >= 0 ? xdomain[0] + pixelWidth * 16 : 0 
    return labelHelper(spacer, ydomain).map((y, i) => <text 
      fontSize={pixelWidth * 12} key={i} 
      x={x - pixelWidth * 8} y={transformY(y)}
      dominantBaseline="middle" textAnchor="middle"
    >
      {roundTo3Places(y)}
    </text>)
  }

  const otherLabels = () => {
    const otherLabels = props.otherLabels ?? ""
    if (otherLabels === "") return []
    return (otherLabels.map((label, i) => <text
      key={i} fontSize={pixelWidth * 20}
      x={label.x} y={transformY(label.y)}
      dominantBaseline="middle" textAnchor="middle"
    >
      {label.text}
    </text>))
  }

  function displayPiecewise(params, i) {
    const segments = params.segments ?? []

    const isFinite = (segmentPart) => segmentPart[0] !== "inf" && segmentPart[0] !== "-inf"
    const startY = (segment) => isFinite(segment.start) ? 
      segment.start[0] * segment.funcParams[0] + segment.funcParams[1] : 
      xdomain[0] * segment.funcParams[0] + segment.funcParams[1]
    const stopY = (segment) => isFinite(segment.stop) ? 
      segment.stop[0] * segment.funcParams[0] + segment.funcParams[1] :
      xdomain[1] * segment.funcParams[0] + segment.funcParams[1]
    const segmentStart = (segment) => [segment.start[0], startY(segment), segment.start[1]]
    const segmentStop = (segment) => [segment.stop[0], stopY(segment), segment.stop[1]]


    const segmentLines = segments.map((segment, j) => <line 
      strokeWidth={pixelWidth} key={j}
      stroke={params.color}
      x1={isFinite(segment.start) ? segment.start[0] : xdomain[0]} y1={transformY(startY(segment))}
      x2={isFinite(segment.stop) ? segment.stop[0] : xdomain[1]} y2={transformY(stopY(segment))}
    />)
    let discontinuities = []
    if (isFinite(segments[0].start)) {
      discontinuities.push(segmentStart(segments[0]))
    }
    if (isFinite(segments[segments.length - 1].stop)) {
      discontinuities.push(segmentStop(segments[segments.length - 1]))
    }

    for (let j = 1; j < segments.length; j++) {
      if (startY(segments[j]) !== stopY(segments[j - 1])) {
        discontinuities.push(segmentStart(segments[j]))
        discontinuities.push(segmentStop(segments[j - 1]))
      }
    }
    return <g key={i}>
      {segmentLines}
      {discontinuities.map((point, j) => <circle
        key={j} fill={point[2] ? params.color : "rgba(1, 1, 1, 0)"} stroke={params.color} strokeWidth={point[2] ? 0 : pixelWidth * 3}
        cx={point[0]} cy={transformY(point[1])}
        r={pixelWidth * 5}
      />)}
    </g>
  }

  return (
    <svg className="graph" width={width} height={height} 
      viewBox={`${xdomain[0]} ${ydomain[0]} ${xdomain[1] - xdomain[0]} ${ydomain[1] - ydomain[0]}`}
      onMouseMove={props.onMouseMove}
      onClick={props.onClick}
    >
      <rect x={xdomain[0]} y={transformY(ydomain[1])} width={xdomain[1] - xdomain[0]} height={ydomain[1] - ydomain[0]} fill="white"/>
      {xIntegerVals.map((e, i) => <line key={i} strokeWidth={pixelWidth} stroke="#ddd" 
        x1={e} x2={e} y1={transformY(ydomain[0])} y2={transformY(ydomain[1])}
      />)}

      {yIntegerVals.map((e, i) => <line key={i} strokeWidth={pixelWidth} stroke="#ddd" 
        x1={xdomain[0]} x2={xdomain[1]} y1={transformY(e)} y2={transformY(e)}
      />)}

      {borders.map((coords, i) => <line key={i} strokeWidth={pixelWidth} stroke="black" {...coords}/>)}
      {axes.map((coords, i) => <line key={i} strokeWidth={pixelWidth} stroke="#222" 
        strokeDasharray={`${pixelWidth * 5} ${pixelWidth * 2.5}`} {...coords}/>)}

      {xAxisLabels()}
      {yAxisLabels()}

      {otherLabels()}

      {lineSegments.map((params, index) => <line 
        strokeWidth={pixelWidth} key={index}
        stroke={params.color}
        x1={params.start[0]} y1={transformY(params.start[1])}
        x2={params.stop[0]} y2={transformY(params.stop[1])}
      />)}

      {lines.map((params, index) => {
        const leftYInt = (params.m * xdomain[0]) + params.b
        const rightYInt = (params.m * xdomain[1]) + params.b
        return <line x1={xdomain[0]} x2={xdomain[1]} 
          y1={transformY(leftYInt)} y2={transformY(rightYInt)}
          strokeWidth={pixelWidth} key={index}
          stroke={params.color ?? "black"}
        />
      })}

      {piecewiseFuncs.map((params, index) => displayPiecewise(params, index))}

      {points.map((point, index) => <circle
        key={index} fill={point.color}
        cx={point.x} cy={transformY(point.y)}
        r={pixelWidth * 5}
      />)}
    </svg>
  )
} 

export default Graph